import React, { useEffect } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { navigate } from 'gatsby';
import Layout from '../components/layout';

const FeedbackPage = ({ location }) => {
  const { search } = location;
  const { paymentId } = queryString.parse(search);

  useEffect(() => {
    axios
      .get(`/api/status?paymentId=${paymentId}`)
      .then(({ data }) => {
        if (data === 'Succeeded') {
          navigate('/sikeres');
        } else {
          navigate('/sikertelen');
        }
      })
      .catch(() => navigate('/sikertelen'));
  }, [paymentId]);

  return (
    <Layout
      seo={{
        title: 'Kis türelmet...',
        description:
          'Sikerélményt viszünk a fiatalok életébe szociális helyzettől függetlenül.'
      }}
    >
      <div className="conatiner flex justify-center">
        <div className="pt-40 pb-20">
          <img alt="Loading" src="/loading.svg" width="" className="mx-auto" />{' '}
          <h2>Kis türelmet...</h2>
        </div>
      </div>
    </Layout>
  );
};

export default FeedbackPage;
